@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	&[data-theme='dark'] {
		background-color: $background-black;
		color: $text-white;

		.eyebrow {
			color: $text-grey-light;
		}
	}

	&[data-theme='light'] {
		background-color: $background-off-white;
		color: $text-black;

		.subheader {
			color: $text-grey-dark;
		}

		.eyebrow {
			color: $text-grey-dark;
		}
	}

	&[data-theme='white'] {
		background-color: $background-white;
		color: $text-black;

		.subheader {
			color: $text-grey-dark;
		}

		.eyebrow {
			color: $text-grey-dark;
		}
	}

	@include section-padding;
}

.wrapper {
	max-width: 90rem;
	margin-inline: auto;

	display: grid;
	grid-template-columns: 4fr 5fr;
	align-items: center;

	gap: $spacing-x-large-desktop;

	@include sm-down {
		grid-template-columns: 1fr;
	}
}

.header {
	margin-top: $spacing-xx-small-desktop;
	max-width: 21ch;
	text-wrap: balance;
}

.subheader {
	max-width: 45ch;
	text-wrap: balance;
}

.cta {
	margin-top: $spacing-large-desktop;
}

.image_container {
	border-radius: $spacing-xx-small-desktop;
	contain: paint;

	position: relative;

	img {
		height: 23rem;
	}

	@include sm-down {
		img {
			height: 18rem;
		}
	}

	@include xs-only {
		img {
			height: 14rem;
		}
	}
}

.image_tag {
	position: absolute;
	bottom: 12px;
	left: 20px;

	color: $text-white;
}

.financing_tag {
	display: flex;
	align-items: center;
}

.financing_tag {
	gap: 0.5em;

	svg {
		height: 1.7em;
	}
}

.fragile_logo {
	height: 1.2em !important;
	width: 5em !important;
}

.benefits {
	display: grid;
	grid-template-columns: auto auto;
	row-gap: 0.75rem;
	column-gap: 1.5rem;

	@include xs-only {
		grid-template-columns: 1fr;
	}

	list-style: none;
	margin: 0;
	padding: 0;

	max-width: 500px;

	margin-block-start: 20px;

	li {
		display: flex;
		gap: 0.5rem;

		svg {
			height: 1.5em;
			width: 1.5em;
		}
	}
}

.disclaimer {
	// "type_base" class has margin-block: 0rem, which overrides margin-block-start in production due to
	// different ordering of CSS rules in prod vs dev.
	// so instead of using !important, we increase specificity using :where(&)
	&:where(&) {
		color: $text-grey-dark;
		margin-block-start: 4rem;
	}
}
